export enum Constants {
  operatedByHA = "HA",
  defaultdestinationImgUrl = "/media/images/backgrounds/water-droplets-on-a-taro-leaf.jpg",
  mainHeaderText = "Check-in online",
  validForm = "VALID",
  customerLevelBasic = 'BASIC',
  customerLevelMemb = 'MEMB',
  customerLevelJpcc = 'JPCC',
  customerLevelHacc = 'HACC',
  customerLevelPlatinum = 'Platinum',
  customerLevelGold = 'Gold',
  customerLevelASRUBY = 'RUBY',
  customerLevelASSAPPHIRE = 'SAPH',
  customerLevelASEMERALD = 'EMER',
  seatClassTypeMcb = 'MAINCABINBASIC',
  validationTypeDocv = 'DOCV',
  validationTypeTimatic = 'Timatic',
  premiumSeatProductId = "0BV",
  firstClassSeatProductId = "0BJ",
  premiumSeatProductType = "premiumSeatUpgrade",
  firstClassSeatProductType = "firstClassCabinUpgrade",
  seatClassTypeCoach = 'Coach',
  seatClassTypeFirstClass = 'FirstClass',
  seatClassTypeExtraComfort = 'ExtraComfort',
  seatClassTypePreffered = 'Preffered',
  seatClassTypeBusiness = 'Business',
  seatMarketTypeNI = "Neighbor Island",
  seatMarketTypeNA = "North America",
  bookingClassMCB = "U",
  marketTypeInternational = 'International',
  codeInfant = '/INF',
  unaccompaniedMinorCodeSSR = 'UNMR',
  ios = "iOS"
}

export const ALERTS = {
  lateCheckInAlert: ['CI06'],
  earlyCheckInAlert: ['CI05'],
  inhibitiedAlert: ['CI16'],
  groupBookingAlert: 'CI10',
  internationalAlert: 'CI12',
  airportAssistanceAlert: ['CI16', 'CI17', 'BP01', 'BP02', 'BP03', 'BP04', 'BP05'],
  restrictedDepartureAlert: 'RestrictedDeparture',
  passengerLevelAlert: 'ElitePassenger',
  passportScanError: 'PassportScanError',
  referOutToKiosk: 'ReferOutToKiosk',
  referOutToAgent: 'ReferOutToAgent',
  referOutToAgentForUnaccompaniedMinor: 'MinorPnr',
  unaccompaniedMinorAlertReasonCode: "CI09",
  unaccompaniedMinorReasonCode: "isMinorPNR",
  targetMessageAlert: ['LAXoutbound', 'SFOoutbound', 'LAXinbound', 'SFOinbound'],
  regulatoryDetailsInhibition: 'regulatoryDetailsInhibition'
};

export const barclaysBinNumbers = {
  '537800': 'HA CC - Retention',
  '537801': 'HA CC - Standard',
  '537802': 'HA CC - Retention',
  '537803': 'HA CC - Standard',
  '547520': 'HA CC - Business',
};

// Note that "VI" doesn't have a assigned Code (yet)
// So creditCardTypeCodes doesn't have a 1-1 relationship with CreditCardTypeNames
export const creditCardTypeCodes = {
  "BA": "001", // VISA
  "IK": "002", // MASTERCARD
  "AX": "003", // AMEX
  "DS": "004", // DISCOVER
  "DC": "005", // DINERS
  "JB": "007", // JCB
  "TP": "040", // UATP
  "CA": "009", // MASTERCARD
};

export enum CreditCardTypeNames {
  "BA" = "VISA", // VISA
  "IK" = "MASTERCARD", // MASTERCARD
  "AX" = "AMERICAN EXPRESS", // AMEX
  "DS" = "DISCOVER", // DISCOVER
  "DC" = "DINERS CLUB INTERNATIONAL", // DINERS
  "JB" = "JCB", // JCB
  "TP" = "UATP", // UATP,
  "CA" = "MASTERCARD", // MASTERCARD
  "VI" = "VISA", // VISA
}

export const seatCategoryArray = [
  {
    categoryType: 'FIRSTCLASS',
    displayName: 'First Class'
  },
  {
    categoryType: 'FIRST CLASS',
    displayName: 'First Class'
  },
  {
    categoryType: 'FIRST',
    displayName: 'First Class'
  },
  {
    categoryType: 'BUSINESS CLASS',
    displayName: 'Business'
  },
  {
    categoryType: 'BUSINESS',
    displayName: 'Business'
  },
];

export enum MilitaryTravelType {
  NONE = 'None',
  ORDERS = 'Orders',
  LEISURE = 'Leisure'
}

export enum SeatClassType {
  First_Class = 'First Class',
  Business = 'Business',
  EXTRA_COMFORT= 'Extra Comfort',
  Main_Cabin = 'Main Cabin',
  Preferred_Seat = 'Preferred Seat'
}

export enum SeatMapCabinType {
  First_Class = 'First',
  Business_First = 'BusinessFirst',
  Economy = 'Economy',
  All = 'All'
}

export enum CabinType {
  First = 'F',
  Economy = 'Y'
}

export enum OrderStatusErrorCodes {
  SFF = 'SFF',
  FSAF = 'FSAF',
  PSAF = 'PSAF',
  FBFF = 'FBFF',
  PBFF = 'PBFF',
  PCNI = 'PCNI',
  BFNO = 'BFNO',
  BFNI = 'BFNI',
  FOPF = 'FOPF',
}

export const linkMinorErrorInfo = [
  {
    errorType: 'itineraryMismatchError',
    errorSentence: 'All flights of Adult to be linked must match flights of Minor.'
  },
  {
    errorType: 'adultNotCheckedInError',
    errorSentence: 'All passengers on Adult PNR must be checked in.'
  },
  {
    errorType: 'reservationNotFoundError',
    errorSentence: 'Resource not found'
  },
];

export const standByPriorityCodes = {
  [Constants.customerLevelPlatinum]: "PFPLT",
  [Constants.customerLevelGold]: "PFGLD"
};

export enum PURPOSE_OF_VISIT {
  BUSINESS = 'business',
  IMMIGRATION = 'immigration',
  STUDIES = 'studies',
  TOURIST = 'tourist',
  WORK = 'work'
}
