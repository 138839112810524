import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { EntityState, getValidateLoyalty } from '~app/store';

@Injectable()
export class LoyaltyDispatchers {
  constructor(private store: Store<EntityState>) {
  }

  getValidateLoyalty(loyaltyNumber: string, firstName: string, lastName: string, birthDate?: string) {
    this.store.dispatch(getValidateLoyalty({ loyaltyNumber, firstName, lastName, ...(birthDate && { birthDate }) }));
  }
}
