import { createFeatureSelector, createSelector, Store } from '@ngrx/store';
import { EntityState } from '~app/store';
import { Injectable } from '@angular/core';
import { LoyaltyState } from '~app/store/reducers/loyalty/loyalty.reducers';


const getEntityState = createFeatureSelector<EntityState>('entityCache');

export const getValidateLoyaltyState = createSelector(
  getEntityState,
  (state: EntityState) => state?.loyalty
);

export const getValidateLoyaltyLoading = createSelector(
  getValidateLoyaltyState,
  (state: LoyaltyState) => state?.loading
);

export const getValidateLoyaltyFailure = createSelector(
  getValidateLoyaltyState,
  (state: LoyaltyState) => state?.error
);

@Injectable()
export class LoyaltySelectors {
  constructor(private store: Store<EntityState>) {
  }

  getValidateLoyaltyState$ = this.store.select(getValidateLoyaltyState);
  getValidateLoyaltyStateLoading$ = this.store.select(getValidateLoyaltyLoading);
  getValidateLoyaltyStateError$ = this.store.select(getValidateLoyaltyFailure);
}
