<section class="p-0">
    <div *ngIf="!flightsData" class="row text-center">
        <div class="col text-center">
            <div class="spinner-border">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div>
    <div *ngIf="!!flightsData" class="row print-area checkin-card">
        <div class="col-md-12" *ngIf="!trip.forConnectionCard">
            <div class="boarding-pass row">
                <div class="col-md-12">
                    <div class="row mt-2">
                        <div class="col-sm-9 bp-content">
                            <div class="row logo-line text-center mb-3">
                                <div [ngSwitch]="trip?.disclosureText" class="col-sm-4 text-left">
                                    <div *ngSwitchCase="rendering.props['operatedByOhana']" id="oa-logo-horizontal-{{trip.boardingPassIndex}}" class="oa-logo-horizontal"></div>
                                    <div *ngSwitchDefault class="ha-logo-horizontal"></div>
                                </div>
                                <div class="col-sm-5 col-sm-8 text-left bold">
                                    <h4 id="boarding-pass-title-{{trip.boardingPassIndex}}">{{ trip?.boardingPassType }}
                                    </h4>
                                </div>
                            </div>
                            <div class="row">
                                <div class='col-1 small'>
                                    <span *haText="rendering.props['name']"></span>
                                </div>
                                <div class='col-7'>
                                    <span id="passenger-full-name-{{trip.boardingPassIndex}}" class='body-copy-bold'
                                        *haText="trip.passengerFullName">
                                    </span>
                                </div>
                                <div class='col-1 pl-0 small'>
                                    <span *haText="rendering.props['date']"></span>
                                </div>
                                <div class='col-2 pl-0'>
                                    <span class='body-copy-bold' id="depart-datetime-{{trip.boardingPassIndex}}">
                                        {{trip?.departureTime | date:'dMMMyy' | uppercase}}
                                    </span>
                                </div>
                            </div>
                            <div class="row">
                                <div class='col-1 small'>
                                    <span *haText="rendering.props['flightInfo']"></span>
                                </div>
                                <div class='col-7'>
                                    <span class='body-copy-bold' id="full-flight-number-{{trip.boardingPassIndex}}"
                                        *haText="trip?.fullFlightNumber">
                                    </span>
                                </div>
                                <div class='col-1 pl-0 small'>
                                    <span *haText="rendering.props['eTicketInfo']"></span>
                                </div>
                                <div class='col-2 pl-0'>
                                    <span id="eticket-{{trip.boardingPassIndex}}" class='body-copy-bold'
                                        *haText="trip?.ticketNumber">
                                    </span>
                                </div>
                            </div>
                            <div class="row">
                                <div class='col-1 small'>
                                    <span *haText="rendering.props['departs']"></span>
                                </div>
                                <div class='col-7'>
                                    <span class='body-copy-bold' id="departure-time-{{trip.boardingPassIndex}}">
                                        {{trip?.departureTime | date:'h:mmaaaaa' | uppercase}}
                                    </span>
                                    <span class='body-copy-bold ml-5' id="origin-full-name-{{trip.boardingPassIndex}}"
                                        *haText="trip?.originFullName">
                                    </span>
                                </div>
                                <div class='col-1 pl-0 small'>
                                    <span *haText="rendering.props['stop']"></span>
                                </div>
                                <div class='col-2 pl-0'>
                                    <span class='body-copy-bold' id="stops-{{trip.boardingPassIndex}}"></span>
                                </div>
                            </div>
                            <div class="row">
                                <div class='col-1 small'>
                                    <span *haText="rendering.props['arrives']"></span>
                                </div>
                                <div class='col-7'>
                                    <span class='body-copy-bold' id="arrival-time-{{trip.boardingPassIndex}}">
                                        {{trip?.arrivalTime | date:'h:mmaaaaa' | uppercase}}
                                    </span>
                                    <span class='body-copy-bold ml-5'
                                        id="destination-full-name-{{trip.boardingPassIndex}}"
                                        *haText="trip?.destinationFullName">
                                    </span>
                                </div>
                                <div class='col-1 pl-0 small'>
                                    <span *haText="rendering.props['confirmation']"></span>
                                </div>
                                <div class='col-2 pl-0'>
                                    <span id="confirmation-code-{{trip.boardingPassIndex}}" class='body-copy-bold'
                                        *haText="resultData?.results[0]?.confirmationCode"></span>
                                </div>
                            </div>
                            <div class="row">
                                <div class='col-1 small'>
                                    <span *haText="rendering.props['loyalty']"></span>
                                </div>
                                <div class='col-7'>
                                    <span class='body-copy-bold' id="member-number-{{trip.boardingPassIndex}}"
                                        *ngIf="trip?.loyalty?.number">{{ trip.loyalty?.company }}{{ trip.loyalty.number | numberMask }}
                                    </span>
                                    <div class='body-copy-bold' id="member-status-{{trip.boardingPassIndex}}"
                                        *haText="trip?.memberStatus | uppercase">
                                    </div>
                                </div>
                                <div class='col-3 pl-0' *ngIf="this.trip?.hasInfant">
                                    <span *haText="rendering.props['seatInf']" id="infant-{{trip.boardingPassIndex}}"class="body-copy-bold"></span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-8">
                                    <div class="row">
                                        <div class="col box-bordered">
                                            <div class="row text-center">
                                                <div class="col small" *haText="rendering.props['boardingStart']"></div>
                                                <div class="col small" *haText="rendering.props['gateInfo']"></div>
                                                <div class="col small" *haText="rendering.props['seatInfo']"></div>
                                                <div class="col small" *haText="rendering.props['zoneInfo']"></div>
                                                <div class="col small" *haText="rendering.props['boardingCloses']">
                                                </div>
                                            </div>
                                            <div class="row text-center">
                                                <div class="col">
                                                    <span class="body-copy-bold"
                                                        id="boarding-time-{{trip.boardingPassIndex}}">
                                                        {{trip?.boardingTime | date:'h:mmaaaaa' | uppercase}}
                                                    </span>
                                                </div>
                                                <div class="col">
                                                    <span class="body-copy-bold"
                                                        id="gate-info-{{trip.boardingPassIndex}}"
                                                        *haText="trip?.gateInfo"></span>
                                                </div>
                                                <div class="col">
                                                    <span class="body-copy-bold"
                                                        *ngIf="trip?.seatNumber !== 'SeeAgent' && !this.trip?.isInfant"
                                                        id="seat-number-{{trip.boardingPassIndex}}">{{trip?.seatNumber}}</span>
                                                    <span class="body-copy-bold"
                                                        *ngIf="trip?.seatNumber === 'SeeAgent'  && !this.trip?.isInfant"
                                                        id="seat-number-{{trip.boardingPassIndex}}">{{rendering.props['seeGateAgent']}}</span>
                                                    <span class="body-copy-bold"
                                                        *ngIf="this.trip?.isInfant"
                                                        id="seat-number-{{trip.boardingPassIndex}}">{{rendering.props['seatInf']}}</span>
                                                </div>
                                                <div class="col">
                                                    <span class="body-copy-bold"
                                                        id="zone-info-level-{{trip.boardingPassIndex}}"
                                                        *haText="rendering.props['zones'][trip?.boardingZone] || trip?.boardingZone">
                                                    </span>
                                                </div>
                                                <div class="col">
                                                    <span class="body-copy-bold"
                                                        id="boarding-close-{{trip.boardingPassIndex}}">
                                                        {{trip.boardingCloseTime | date:'h:mmaaaaa' | uppercase}}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="!this.trip?.isInfant">
                                        <div class="col-6" id="boarding-pass-data-{{trip.boardingPassIndex}}">
                                            <img [src]="boardingPassData" alt="Boarding Pass Data" />
                                        </div>
                                        <div class="col middle-column">
                                            <div class="row">
                                                <div class="col body-copy-bold">
                                                    <span id="priority-{{trip.boardingPassIndex}}"
                                                        *haText="trip?.priority">
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="row" *ngIf="trip.operatedBy !== 'HA'">
                                                <div class="col small">
                                                    <div *haText="rendering.props['operatedBy']"></div>
                                                    <div>
                                                        <span id="disclosure-text-{{trip.boardingPassIndex}}"
                                                            *haText="trip?.disclosureText">
                                                        </span>
                                                        {{ trip?.operatedByDescription | uppercase }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mt-2" *ngIf="trip?.disclosureText">
                                                <div class="col small">
                                                    <div *haText="rendering.props['soldAs']"></div>
                                                    <div>
                                                        <span id="sold-as-flight-{{trip.boardingPassIndex}}">
                                                            {{trip?.airlineCode}} {{trip?.flightNumber}}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col body-copy-bold">
                                                    <span id="lounge-{{trip.boardingPassIndex}}">
                                                        {{trip?.lounge === 'None' || '' ? '' : trip?.lounge | uppercase}}
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col foot-note-bold">
                                                    <span id="mcb-seat-type-{{trip.boardingPassIndex}}" *haText="trip.seatClassType">
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-4 pl-0">
                                    <div class="row">
                                        <div class='col small'>
                                            <span *haText="rendering.props['bagCountBagTag']"></span>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class='col bold small'>
                                            <span id="bag-type-{{bag.productId}}-{{trip.boardingPassIndex}}"
                                                *ngFor="let bag of trip?.bags">{{getBagContent(bag.productId)?.title}}:
                                                {{bag?.count}}<br></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-3 box-dashed">
                            <div class="row">
                                <div *ngIf="trip?.tsaPreCheck" class="col">
                                    <img [src]='BP_TSA_PRE_LOGO' width="70%" alt="Get TSA Pre Logo"
                                        id="tsa-pre-logo-{{trip.boardingPassIndex}}" />
                                </div>
                            </div>
                            <div class="row tsa-padding">
                                <div class="col-3 small">
                                    <span *haText="rendering.props['name']"></span>
                                </div>
                                <div class="col-9 text-left">
                                    <span class='body-copy-bold' id="passenger-full-name-subset-{{trip.boardingPassIndex}}">
                                        {{trip.passengerFullName | uppercase}}
                                    </span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-3 small">
                                    <span *haText="rendering.props['flightInfo']"></span>
                                </div>
                                <div class="col-9 text-left">
                                    <span class='body-copy-bold' id="full-flight-number-subset-{{trip.boardingPassIndex}}"
                                        *haText="trip?.fullFlightNumber">
                                    </span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-3 small">
                                    <span *haText="rendering.props['departs']"></span>
                                </div>
                                <div class="col-9 text-left">
                                    <span class='body-copy-bold' id="departure-time-subset-{{trip.boardingPassIndex}}">
                                        {{trip?.departureTime | date:'h:mmaaaaa' | uppercase}}
                                    </span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-3 small">
                                    <span *haText="rendering.props['arrives']"></span>
                                </div>
                                <div class="col-9 text-left">
                                    <span class='body-copy-bold' id="arrival-time-subset-{{trip.boardingPassIndex}}">
                                        {{trip?.arrivalTime | date:'h:mmaaaaa' | uppercase}}
                                    </span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-3 small">
                                    <span *haText="rendering.props['loyalty']"></span>
                                </div>
                                <div class="col-9 text-left">
                                    <div class='body-copy-bold' id="member-number-subset-{{trip.boardingPassIndex}}"
                                    *ngIf="trip?.loyalty?.number">{{ trip.loyalty?.company }}{{ trip.loyalty.number | numberMask }}
                                    </div>
                                    <div class='body-copy-bold' id="member-status-subset-{{trip.boardingPassIndex}}"
                                        *haText="trip?.memberStatus | uppercase">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-1"></div>
                                <div class="col-8 box-bordered box-bordered-left">
                                    <div class="row">
                                        <div class="col text-left body-copy" *haText="rendering.props['gateInfo']">
                                        </div>
                                        <div class="col text-left pl-0">
                                            <span class="body-copy-bold"
                                                id="gate-info-subset-{{trip.boardingPassIndex}}" *haText="trip?.gateInfo"></span>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col text-left body-copy">
                                            <span>{{rendering.props['seatInfo']}}:</span>
                                        </div>
                                        <div class="col text-left pl-0">
                                            <span class="body-copy-bold"
                                                *ngIf="trip?.seatNumber !== 'SeeAgent' && !this.trip?.isInfant"
                                                id="seat-number-subset-{{trip.boardingPassIndex}}">{{trip?.seatNumber}}</span>
                                            <span class="body-copy-bold"
                                                *ngIf="trip?.seatNumber === 'SeeAgent'  && !this.trip?.isInfant"
                                                id="seat-number-subset-{{trip.boardingPassIndex}}">{{rendering.props['seeGateAgent']}}</span>
                                            <span class="body-copy-bold"
                                                *ngIf="this.trip?.isInfant"
                                                id="seat-number-subset-{{trip.boardingPassIndex}}">{{rendering.props['seatInf']}}</span>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col text-left body-copy">
                                            <span>{{rendering.props['zoneInfo']}}:</span>
                                        </div>
                                        <div class="col text-left pl-0">
                                            <span class="body-copy-bold" id="zone-subset-{{trip.boardingPassIndex}}">
                                                {{ rendering.props['zones'][trip?.boardingZone] || trip?.boardingZone }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row seat-category-margin">
                                <div class="col body-copy-bold text-left">
                                    <span id="mcb-seat-type-{{trip.boardingPassIndex}}" *haText="trip.seatClassType">
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-4" *ngIf="resultData?.results[0]?.isPassTravel">
                      <div class="col">
                        <span class="body-copy" *haText="rendering?.props['nonRevNotice']"></span>
                      </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col">
                            <span id="boarding-pass-seq-{{trip.boardingPassIndex}}" class="body-copy-bold">
                                {{rendering.props['seq']}} {{ ('00' + trip.boardingPassIndex).slice(-3)}}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-5" *ngIf="walletQRCode">
                <div class="col">
                  <div class="row wallet-box mb-5" *ngFor="let mobileWallet of rendering.props.mobileWalletAds">
                    <div class="wallet-components">
                        <div class="subtitle-bold" *haText="mobileWallet.title"></div>
                        <div class="body-copy-light-text" *haText="mobileWallet.instructions"></div>
                        <img class="mobile-wallet pt-4" [ngClass]="mobileWallet.type.toLowerCase()">
                    </div>
                    <div>
                      <img [src]="walletQRCode" class="qr-properties" alt="Wallet QR Code">
                    </div>
                  </div>
                </div>
            </div>
            <div class="row">
                <div class="col pl-0 body-copy">
                    <span *haText="rendering.props['subjectToChange']"></span>
                </div>
            </div>
            <div id="next-steps" class="row mt-3">
                <div class="col-12 subtitle text-danger px-0" *haText="rendering.props['nextStepsTitle']"></div>
                <div *ngIf="(isInternational$ | async); else domesticNextSteps" id="international-next-steps-text" class="col-12 px-0">
                    <span class="body-copy-bold" *haText="rendering.props['boardingPassWithoutCheckinItemsTitle']"></span>
                    <p *haRichText="rendering.props['boardingPassWithoutCheckinItemsInternational']"></p>
                    <span class="body-copy-bold" *haText="rendering.props['boardingPassWithCheckinItemsTitle']"></span>
                    <p *haRichText="rendering.props['boardingPassWithCheckinItemsInternational']"></p>
                    <span class="body-copy-bold" *haText="rendering.props['baggageMessageInternational']"></span>
                </div>
                <ng-template #domesticNextSteps>
                    <div id="domestic-next-steps-text" class="col-12 px-0">
                        <span class="body-copy-bold" *haText="rendering.props['boardingPassWithoutCheckinItemsTitle']"></span>
                        <p *haRichText="rendering.props['boardingPassWithoutCheckinItems']"></p>
                        <span class="body-copy-bold" *haText="rendering.props['boardingPassWithCheckinItemsTitle']"></span>
                        <p *haRichText="rendering.props['boardingPassWithCheckinItems']"></p>
                        <span class="body-copy-bold" *haText="rendering.props['baggageMessage']"></span>
                    </div>
                </ng-template>
            </div>
        </div>
        <div class="col-md-12" *ngIf="trip.forConnectionCard">
            <div class="boarding-pass row">
                <div class="col-md-12">
                    <div class="row mt-2">
                        <div class="col-sm-12 bp-content">
                            <div class="row logo-line text-center mb-3">
                                <div class="col-sm-4 text-left">
                                    <div class="ha-logo-horizontal" alt="HA Horizontal Logo"></div>
                                </div>
                                <div class="col-sm-5 col-sm-8 text-left bold">
                                    <h4 id="boarding-pass-title-{{trip.boardingPassIndex}}" *haText="trip?.boardingPassType">
                                    </h4>
                                </div>
                            </div>
                            <div class="row">
                                <div class='col-1 small'>
                                    <span *haText="rendering.props['name']"></span>
                                </div>
                                <div class='col-7'>
                                    <span id="passenger-full-name-{{trip.boardingPassIndex}}" class='body-copy-bold'
                                    *haText="trip?.passengerFullName">
                                    </span>
                                </div>
                            </div>
                            <div class="row">
                                <div class='col-1 small'>
                                    <span *haText="rendering.props['flightInfo']"></span>
                                </div>
                                <div class='col-2'>
                                    <span class='body-copy-bold' id="full-flight-number-{{trip.boardingPassIndex}}"
                                    *haText="trip?.fullFlightNumber">
                                    </span>
                                </div>
                                <div class='col-1 pl-0 small'>
                                    <span *haText="rendering.props['date']"></span>
                                </div>
                                <div class='col-2 pl-0'>
                                    <span class='body-copy-bold' id="depart-datetime-{{trip.boardingPassIndex}}">
                                        {{trip?.departureTime | date:'ddMMMyy'}}
                                    </span>
                                </div>
                                <div class='col-1 pl-0 small'>
                                    <span *haText="rendering.props['confirmation']"></span>
                                </div>
                                <div class='col-2 pl-0'>
                                    <span id="confirmation-code-{{trip.boardingPassIndex}}"
                                        class='body-copy-bold' *haText="resultData?.results[0]?.confirmationCode"></span>
                                </div>
                            </div>
                            <div class="row">
                                <div class='col-1 small'>
                                    <span *haText="rendering.props['departs']"></span>
                                </div>
                                <div class='col-7'>
                                    <span class='body-copy-bold' id="departure-time-{{trip.boardingPassIndex}}">
                                        {{trip?.departureTime | date:'h:mmaaaaa' | uppercase}}
                                    </span>
                                    <span class='body-copy-bold ml-5' id="origin-full-name-{{trip.boardingPassIndex}}"
                                    *haText="trip?.originFullName">
                                    </span>
                                </div>
                            </div>
                            <div class="row">
                                <div class='col-1 small'>
                                    <span *haText="rendering.props['arrives']"></span>
                                </div>
                                <div class='col-7'>
                                    <span class='body-copy-bold' id="arrival-time-{{trip.boardingPassIndex}}">
                                        {{trip?.arrivalTime | date:'h:mmaaaaa' | uppercase}}
                                    </span>
                                    <span class='body-copy-bold ml-5'
                                        id="destination-full-name-{{trip.boardingPassIndex}}"
                                        *haText="trip?.destinationFullName">
                                    </span>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col text-center call-out-bold" *haText="rendering.props['noticeTitleDesktop']">
                                </div>
                            </div>
                            <div class="row">
                                <div class="col text-left foot-note">
                                    <span *haRichText="rendering.props['noticeDetails']">
                                    </span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col text-center call-out-bold">
                                    {{rendering.props['flightOperatedBy']}}: {{trip?.operatedByDescription}}
                                    ({{trip?.operatedBy}})
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col text-center">
                                    <h4 *haText="rendering.props['notValidForBoardingDesktop']"></h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row border-top-dashed mt-4 mb-4">
            </div>
            <div class="row">
                <div class="col pl-0 body-copy">
                    <span *haText="rendering.props['subjectToChange']"></span>
                </div>
            </div>
            <div id="oa-next-steps" class="row mt-3">
                <div class="col-12 subtitle text-danger px-0" *haText="rendering.props['nextStepsTitle']"></div>
                <div id="oa-next-steps-text" class="col-12 px-0">
                    <span class="body-copy-bold" *haText="rendering.props['boardingPassWithoutCheckinItemsTitle']"></span>
                    <p *haText="rendering.props['boardingPassWithoutCheckinItemsOa']"></p>
                    <span class="body-copy-bold" *haText="rendering.props['boardingPassWithCheckinItemsTitle']"></span>
                    <p *haText="rendering.props['boardingPassWithCheckinItemsOa']"></p>
                    <span class="font-italic" *haText="rendering.props['tsaSecurityCheckpoint']"></span>
                </div>
            </div>
        </div>
    </div>
</section>
