/**
 * All feature flag constants (keys, supported flags) for this SPA should be managed
 * in this namespace.
 */
export namespace FeatureFlagConstants {

  // Unique feature flag keys for this SPA should be added here
  // and referenced by supportedFeatureFlags below.
  export const WEB_CHECK_IN_MANUAL_PASSPORT_FORM = 'web-check-in-manual-passport-form';
  export const WEB_CHECK_IN_SPA_ENABLED = 'web-check-in-spa-enabled';
  export const WEB_CHECK_IN_SPA_GROUP_BOOKING_ENABLED = 'web-check-in-spa-group-booking-enabled';
  export const WEB_CHECK_IN_SPA_INT1_ENABLED = 'web-check-in-spa-int1-enabled';
  export const WEB_CHECK_IN_SPA_INTERNATIONAL_ENABLED = 'web-check-in-spa-international-enabled';
  export const WEB_CHECK_IN_SPA_OTHER_AIRLINE_REGULAR_BAGS_ENABLED = 'web-check-in-spa-other-airline-regular-bags-enabled';
  export const WEB_CHECK_IN_SPA_TARGET_MESSAGING_MODAL_ENABLED = 'web-check-in-spa-target-messaging-modal-enabled';
  export const ENABLE_ADDING_ALASKA_FF_NUMBER = 'enable-adding-alaska-ff-number';
  export const ENABLE_ADDING_OA_FF_NUMBER = 'enable-adding-oa-ff-number';
  export const WEB_SPA_ALASKA_FF_VALIDATION_ENABLED = 'web-spa-alaska-ff-validation-enabled';

  /**
   * Supported feature flags for this SPA should be added here along with the
   * appropriate default value.  Used by FeatureFlagService.
   * <key: default_value>
   */
  export const supportedFeatureFlags = {
    [WEB_CHECK_IN_MANUAL_PASSPORT_FORM]: false,
    [WEB_CHECK_IN_SPA_ENABLED]: true,
    [WEB_CHECK_IN_SPA_GROUP_BOOKING_ENABLED]: true,
    [WEB_CHECK_IN_SPA_INT1_ENABLED]: false,
    [WEB_CHECK_IN_SPA_INTERNATIONAL_ENABLED]: true,
    [WEB_CHECK_IN_SPA_OTHER_AIRLINE_REGULAR_BAGS_ENABLED]: true,
    [WEB_CHECK_IN_SPA_TARGET_MESSAGING_MODAL_ENABLED]: true,
    [ENABLE_ADDING_ALASKA_FF_NUMBER]: false,
    [ENABLE_ADDING_OA_FF_NUMBER]: false,
    [WEB_SPA_ALASKA_FF_VALIDATION_ENABLED]: false,
  };

  // Maps to the environment setting key name
  export const FEATURE_FLAG_LOCAL_STORAGE_KEY_NAME = "LAUNCHDARKLY_USER";
}
