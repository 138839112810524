<mat-accordion>
  <mat-expansion-panel [expanded]="isSinglePax && !singlePaxLoyaltyFilled" #panel>
    <mat-expansion-panel-header class="pl-0">
      <mat-panel-title (click)="panel.toggle(); $event.stopPropagation()">
        <div class="container" id="pax-card-{{listIndex}}">
          <div class="row">
            <mat-checkbox id="passenger-select-checkbox-{{listIndex}}" class="aloha-pink center-checkbox pt-2"
              [(ngModel)]="isPaxPreselected" name="passenger-checkbox-{{listIndex}}"
              (keydown.space)="togglePassengerCheckbox($event)"
              (keydown.enter)="togglePassengerCheckbox($event)"
              (keyup.space)="$event.preventDefault();" (keyup.enter)="$event.preventDefault();"
              (click)="togglePassengerCheckbox($event); $event.preventDefault();"
              [checked]="isPaxPreselected" *ngIf="!isSinglePax" >
            </mat-checkbox>
            <!-- Desktop View Start -->
            <div (keyup.space)="togglePassengerCheckbox($event)" (keyup.enter)="togglePassengerCheckbox($event)" (click)="togglePassengerCheckbox($event)" class="col d-none d-md-block" [ngClass]="isSinglePax ? 'pl-0' : 'pl-4'">
              <span class="subtitle-bold" id="passenger-name-{{listIndex}}">
                {{ detail?.passengerName.firstName | titlecase}} {{ detail?.passengerName.lastName | titlecase}}
                <span *ngIf='!!passenger?.lapInfant' id="lap-infant-name-{{listIndex}}" class="pl-1">
                  (+ {{ passenger?.lapInfant?.infantName?.firstName | titlecase }} {{ passenger?.lapInfant?.infantName?.lastName | titlecase }})
                </span>
              </span>
              <div class="row passenger-state-row">
                <div id="remove-infant-link-{{listIndex}}" *ngIf='!!passenger?.lapInfant' class="d-flex align-items-center margin-right-50 small aloha-pink">
                  <span tabindex="0" (keydown.space)="clickRemoveInfantLink(); $event.stopPropagation();" (keydown.enter)="clickRemoveInfantLink(); $event.stopPropagation();" (click)="clickRemoveInfantLink() ; $event.stopPropagation();" *haText="rendering?.props['removeInfant']" role="button"></span>
                </div>
                <div id="passenger-loyalty-display-{{listIndex}}"
                  class="small-light-text text-nowrap margin-right-50 d-flex align-items-center"
                  *ngIf="passenger?.loyalty">
                  {{ passengerCompanyText }}: {{ passenger.loyalty.company === 'HA' ? (passenger?.loyalty.number | numberMask) : passenger?.loyalty.number }}
                </div>
                <div *ngIf="isCheckedIn" class="d-flex align-items-center margin-right-50">
                  <i id="already-checked-in-icon-{{listIndex}}" class="ha-icon icon-check-circle aloha-pink"></i>
                  <span id="already-checked-in-text-{{listIndex}}" class="small-light-text passenger-state"
                    *haText="rendering.props['alreadyCheckedIn']"></span>
                </div>
                <div *ngIf="passenger?.loyalty?.level" id="passenger-{{passenger?.loyalty?.level | lowercase}}-member-{{listIndex}}"
                  class="icon-star-{{passenger?.loyalty?.level | lowercase}}-16 icon-16 d-flex align-self-center text-nowrap margin-right-50 member-level">
                  <span *haText="rendering.props[passenger?.loyalty?.level]" class="pl-2 small-light-text"></span>
                </div>
                <div class="d-flex align-items-center margin-right-50" *ngIf="detail?.militaryTravelType">
                  <i class="icon-elite-benefits d-flex"></i>
                  <span id="military-{{listIndex}}" class="small-light-text passenger-state ml-2"
                    *haText="rendering?.props['military']"></span>
                </div>
              </div>
            </div>
            <div class="col-3 d-none d-md-block text-right align-self-center expansion-panel-toggle">
              <span id="frequent-flyer-number-{{listIndex}}" class="frequent-flyer-number aloha-pink foot-note-bold" *haText="rendering?.props['frequentFlyerNumber']"></span>
            </div>
            <!-- Desktop View End -->
            <!-- Mobile View Start -->
            <div class="col d-sm-block d-md-none">
              <span id="passenger-name-mobile-{{listIndex}}" class="subtitle-bold">
                {{ detail?.passengerName.firstName | titlecase}} {{ detail?.passengerName.lastName | titlecase}}
              </span>
              <div class="row small-light-text">
                <div class="col passenger-states">
                  <span *ngIf='!!passenger?.lapInfant' id="lap-infant-name-{{listIndex}}" class="subtitle-bold">
                    (+ {{ passenger?.lapInfant?.infantName?.firstName | titlecase }} {{ passenger?.lapInfant?.infantName?.lastName | titlecase }})
                  </span>
                  <div *ngIf='!!passenger?.lapInfant' class="small aloha-pink remove-infant-link pb-2 pl-1">
                    <span (click)="clickRemoveInfantLink(); $event.stopPropagation();" (keydown.space)="clickRemoveInfantLink()" (keydown.enter)="clickRemoveInfantLink()" *haText="rendering?.props['removeInfant']" role="button" tabindex="0"></span>
                  </div>
                  <div class="pb-2" *ngIf="passenger?.loyalty">
                    {{ passengerCompanyText }}: {{ passenger.loyalty.company === 'HA' ? (passenger?.loyalty.number | numberMask) : passenger?.loyalty.number }}
                  </div>
                  <div *ngIf="isCheckedIn" class="d-flex pb-2 ml-1">
                    <i id="already-checked-in-icon-mobile-{{listIndex}}" class="ha-icon icon-check-circle aloha-pink"></i>
                    <span id="already-checked-in-text-mobile-{{listIndex}}"
                      class="small-tertiary-text already-checked-in-text passenger-state pl-2"
                      *haText="rendering.props['alreadyCheckedIn']"></span>
                  </div>
                  <div *ngIf="passenger?.loyalty?.level" id="passenger-{{passenger?.loyalty?.level | lowercase}}-member-mobile-{{listIndex}}"
                    class="icon-star-{{passenger?.loyalty?.level | lowercase}}-16 icon-16 d-flex ml-1">
                    <span *haText="rendering.props[passenger?.loyalty?.level]" class="pl-2 text-nowrap small-light-text"></span>
                  </div>
                  <div class="d-flex pb-2 ml-1" *ngIf="detail?.militaryTravelType">
                    <i class="icon-elite-benefits"></i>
                    <span id="military-mobile-{{listIndex}}" class="passenger-state small-tertiary-text pl-2" *haText="rendering?.props['military']"></span>
                  </div>
                </div>
                <div class="frequent-flyer-number-div expansion-panel-toggle">
                  <span class="frequent-flyer-number aloha-pink foot-note-bold" *haText="rendering?.props['frequentFlyerNumber']"></span>
                </div>
              </div>

            </div>

            <!-- Mobile View End-->
          </div>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <form id="frequent-flyer-form" [formGroup]="frequentFlyerNumbersForm" class="container">
      <p *haText="rendering?.props?.frequentFlyerProgram"></p>
      <div class="row">
        <div class="col-12 col-lg">
          <div class="row">
            <ha-drop-down class="col-12 col-md-6"
              [dropDownOptions]="frequentFlyerOptions"
              [content]="frequentFlyerProgramContent"
              [control]="frequentFlyerNumbersForm.get('loyalty.company')"
              formFieldAppearance="outline"
              [disableDropDown]="isPassTravel || passenger?.loyalty?.company"
            >
            </ha-drop-down>
            <ha-input-field id="frequent-flyer-number-input-{{listIndex}}"
              class="col-12 col-md-6"
              [content]="frequentFlyerNumberInputContent"
              [validationStates]="memberNumberValidationStates"
              [control]="frequentFlyerNumbersForm.get('loyalty.number')"
              [disableInput]="isPassTravel || passenger?.loyalty?.number"
              [matTooltip]="frequentFlyerApiErrorTooltip()"
              formFieldAppearance="outline"
            >
            </ha-input-field>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-lg">
          <p *haText="rendering?.props['knownTravelerTitle']"></p>
          <div class="row">
            <ha-drop-down id="known-traveler-number-country-dropdown-{{listIndex}}"
              class="col-12 col-md-6" [content]="ktnCountryDropdownContent" [dropDownOptions]="countriesList"
              [control]="frequentFlyerNumbersForm.get('knownTravelerNumber.issuingCountry')">
            </ha-drop-down>
            <ha-input-field id="known-traveler-number-input-{{listIndex}}" class="col-12 col-md-6" [content]="ktnInputContent"
              [control]="frequentFlyerNumbersForm.get('knownTravelerNumber.ktn')"
              formFieldAppearance="outline" maxLength="25">
            </ha-input-field>
          </div>
        </div>
        <div class="col-12 col-lg">
          <p *haText="rendering?.props['redressNumberLabel']"></p>
          <div class=" row">
            <ha-drop-down id="redress-number-country-dropdown-{{listIndex}}" class="col-12 col-md-6"
              [content]="redressCountryDropdownContent" [dropDownOptions]="countriesList"
              [control]="frequentFlyerNumbersForm.get('redressNumber.issuingCountry')">
            </ha-drop-down>
            <ha-input-field id="redress-number-input-{{listIndex}}" class="col-12 col-md-6" [content]="redressInputContent"
              [control]="frequentFlyerNumbersForm.get('redressNumber.redress')" formFieldAppearance="outline" maxLength="25">
            </ha-input-field>
          </div>
        </div>
      </div>
    </form>
  </mat-expansion-panel>
</mat-accordion>
