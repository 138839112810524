import { Injectable } from '@angular/core';
import { BaseService } from '~app/store/services/base.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '~environments/environment';
import { SessionSelectors } from '../session/session.selectors';

export interface GetValidateLoyaltyRes {
  isValid: boolean;
}

@Injectable({
  providedIn: 'root'
})

export class LoyaltyService extends BaseService {

  constructor(
    http: HttpClient,
    sessionSelectors: SessionSelectors
  ) {
    super(http, sessionSelectors);
  }

  getValidateLoyaltyService(loyaltyNumber: string, firstName: string, lastName: string, birthDate?: string): Observable<GetValidateLoyaltyRes> {
    let URL_LOYALTY = `${environment['exp-web-checkin-v2-api']}/loyalty/${loyaltyNumber}/validate?firstName=${firstName}&lastName=${lastName}`;
    if (birthDate) {
      URL_LOYALTY += `&birthDate=${birthDate}`;
    }
    return this.http.get<GetValidateLoyaltyRes>(URL_LOYALTY);
  }
}
