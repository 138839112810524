import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { LoyaltyService } from '~app/store/services/loyalty/loyalty.service';
import * as LoyaltyActions from '~app/store/actions/loyalty.actions';


@Injectable()
export class LoyaltyEffects {
  constructor(private actions$: Actions, private loyaltyService: LoyaltyService) {
  }

  validateLoyalty$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoyaltyActions.getValidateLoyalty),
      switchMap(({loyaltyNumber, firstName, lastName, birthDate}) =>
        this.loyaltyService.getValidateLoyaltyService(loyaltyNumber, firstName, lastName, birthDate).pipe(
          map(response => LoyaltyActions.getValidateLoyaltySuccess({response})),
          catchError(error => of(LoyaltyActions.getValidateLoyaltyError(error)))
        )
      )
    )
  );
}