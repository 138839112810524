import { Action, createReducer, on } from '@ngrx/store';
import * as LoyaltyActions from '~app/store/actions/loyalty.actions';
import { GetValidateLoyaltyRes } from '~app/store/services/loyalty/loyalty.service';

export interface LoyaltyState {
  loading: boolean;
  data: GetValidateLoyaltyRes | null;
  error: any | null;
}

export const initialState: LoyaltyState = {
  loading: false,
  data: null,
  error: null,
};

export const loyaltyReducer = createReducer(
  initialState,
  on(LoyaltyActions.getValidateLoyalty, () => ({...initialState, loading: true})),
  on(LoyaltyActions.getValidateLoyaltySuccess, (state, {response}) => ({
    ...state,
    loading: false,
    data: response,
    error: null,
  })),
  on(LoyaltyActions.getValidateLoyaltyError, (state, {error}) => ({
    ...state,
    loading: false,
    error,
  }))
);

export function reducer(state: LoyaltyState | undefined, action: Action) {
  return loyaltyReducer(state, action);
}
