import { createAction, props } from '@ngrx/store';
import { GetValidateLoyaltyRes } from '~app/store/services/loyalty/loyalty.service';

export const getValidateLoyalty = createAction(
  '[Loyalty] Get Validate Loyalty',
  props<{ loyaltyNumber: string, firstName: string, lastName: string, birthDate?: string }>()
);

export const getValidateLoyaltySuccess = createAction(
  '[Loyalty] Get Validate Loyalty Success',
  props<{ response: GetValidateLoyaltyRes }>()
);

export const getValidateLoyaltyError = createAction(
  '[Loyalty] Get Validate Loyalty Error',
  props<{ error: any }>()
);
